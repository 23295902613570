import React, { useEffect } from "react";
import Banner from "./banner";
import Info from "./info";
import Footer from "./footer";
import InfoSlide from "./info-slide";
import '../styles/main.scss';
import Testimonials from "./testimonials";


export default function Main({ posts }) {

  useEffect(() => {
    if (typeof window !== "undefined" && (window.location.hash.includes('_token=')) || window.location.hash.includes('_recovery_token=')) {
      window.location.replace('/admin/' + window.location.hash)
    }

  })

  return (
    <main>
      <Banner posts={posts} />
      <Info />
      <Testimonials />
      <InfoSlide />
      <Footer />
    </main>
  )
}
